import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { MenuItem, SelectItem } from 'primeng/api';

import { AccountService } from '../../../shared/services/account.service';
import { ResourceService } from '../../../shared/services/resource.service';
import { SpinnerService } from '../../../shared/layout/spinner/spinner.service';
import { ManageIncomeForm } from './manage-income.form';
import { AccountReceivableDetails } from 'src/shared/models/account-receivable-details';
import { BusinessEntity } from 'src/shared/models/business-entity.model';
import { AccountReceivable } from 'src/shared/models/account-receivable.model';
import { WithholdingForm } from './withholding.form';
import { Withholding } from 'src/shared/models/withholding.model';
import { WithholdingService } from 'src/shared/services/withholding.service';
import { WorkHoursForm } from './work-hours.form';
import { WorkHoursService } from 'src/shared/services/work-hours.service';
import { WorkHours } from 'src/shared/models/work-hours.model';

@Component({
  selector: 'iots-manage-income',
  templateUrl: './manage-income.component.html',
  styleUrls: ['./manage-income.component.scss']
})
export class ManageIncomeComponent implements OnInit {

  accounts: AccountReceivableDetails[] = [];
  incomeForm: ManageIncomeForm;
  incomeFormVisible: boolean = false;
  editIncomeForm: ManageIncomeForm;
  editIncomeFormVisible: boolean = false;
  selectedAccount: AccountReceivableDetails;
  withholdingsVisible: boolean = false;
  withholdings: Withholding[];
  newWithholdingForm: WithholdingForm;
  newWithholdingFormVisible: boolean = false;
  editWithholdingForm: WithholdingForm;
  editWithholdingFormVisible: boolean = false;
  workHours: WorkHours[];
  workHoursPanelVisible: boolean = false;
  newWorkHoursForm: WorkHoursForm;
  editWorkHoursForm: WorkHoursForm;
  rateTypes: SelectItem[];

  constructor(
    private spinnerService: SpinnerService,
    private accountService: AccountService,
    private withholdingService: WithholdingService,
    private workHoursService: WorkHoursService,
    private resourceService: ResourceService) {
      this.rateTypes = [
        { label: 'Percentage', value: '%' },
        { label: 'Dollar Value', value: '$' }
      ]
    }

  ngOnInit() {
    this.spinnerService.startSpinner()
    this.loadAccountsReceivable().subscribe(_ => this.spinnerService.stopSpinner());
  }

  loadAccountsReceivable = ()=> {
    return this.accountService.getAccountReceivableDetails()
      .pipe(tap(rslt => {
        this.accounts = rslt;
      }));
  }

  onClickAddSource = () => {
    this.incomeForm = new ManageIncomeForm();
    this.incomeFormVisible = true;
  }

  onClickCloseIncomeForm = () => {
    this.incomeForm = null;
    this.incomeFormVisible = false;
  }

  onBusinessEntitySelected = () => {
    this.incomeForm.changeState('create-account');
  }

  onClickPrevious = () => {
    this.incomeForm.changeState('choose-entity');
  }

  onCompleteAddIncome = () => {
    this.incomeForm = null;
    this.incomeFormVisible = false;
    this.spinnerService.startSpinner();
    this.loadAccountsReceivable().subscribe(_ => this.spinnerService.stopSpinner());
  }

  onClickEditIncome = (bill: AccountReceivableDetails) => {
    let observable = combineLatest(
      this.resourceService.getBusinessEntity(bill.BusinessEntityId),
      this.accountService.getAccountReceivable(bill.AccountReceivableId),
      (entity: BusinessEntity, account: AccountReceivable) => ({entity, account})
    );
    observable.subscribe(rslt => {
      this.editIncomeForm = new ManageIncomeForm();
      this.editIncomeForm.accountReceivable = rslt.account;
      this.editIncomeForm.businessEntity = rslt.entity;
      this.editIncomeFormVisible = true;
    });
  }

  onClickCloseEditIncomeForm = () => {
    this.editIncomeForm = null;
    this.editIncomeFormVisible = false;
  }

  onEditBusinessEntitySelected = () => {
    this.editIncomeForm.changeState('create-account');
  }

  onClickEditPrevious = () => {
    this.editIncomeForm.changeState('choose-entity');
  }

  onCompleteEditIncome = () => {
    this.editIncomeForm = null;
    this.editIncomeFormVisible = false;
    this.spinnerService.startSpinner();
    this.loadAccountsReceivable().subscribe(_ => this.spinnerService.stopSpinner());
  }

  getAccountOptions(act: AccountReceivableDetails): MenuItem[] {
    let options: MenuItem[] = [
      { label: 'Withholdings', icon: 'pi pi-eye', command: () => this.loadWitholdings(act)},
      {
        label: 'Manage Hours',
        icon: 'pi pi-clock',
        command: () => this.loadWorkHours(act),
        disabled: act.ReceivableType != 'Hourly Wage'
      },
      {
        label: 'Visit Website',
        icon: 'pi pi-globe',
        command: () => { window.open(act.Website); },
        disabled: !act.Website
      }
    ];
    return options;
  }

  loadWitholdings = (account: AccountReceivableDetails) => {
    this.spinnerService.startSpinner();
    this.selectedAccount = account;
    this.newWithholdingFormVisible = false;
    this.editWithholdingFormVisible = false;
    this.newWithholdingForm = new WithholdingForm(account.AccountReceivableId);
    this.withholdingService.getWithholdings(account.AccountReceivableId)
      .subscribe(rslt => {
        this.spinnerService.stopSpinner();
        this.withholdings = rslt;
        this.withholdingsVisible = true;
      });
  }

  onClickAddWithholding = () => {
    let id = this.selectedAccount.AccountReceivableId;
    this.newWithholdingForm = new WithholdingForm(id);
    this.newWithholdingFormVisible = true;
  }

  addWithholding = () => {
    if (!this.newWithholdingForm.isValid) {
      alert('Invalid form');
      return;
    }
    this.spinnerService.startSpinner();
    let withholding = this.newWithholdingForm.getObjectModel();
    this.withholdingService.addWithholding(withholding)
      .pipe(switchMap(_ => this.loadAccountsReceivable()))
      .subscribe(_ => {
        this.newWithholdingFormVisible = false;
        this.newWithholdingForm = new WithholdingForm(this.selectedAccount.AccountReceivableId);
        this.loadWitholdings(this.selectedAccount)
      });
  }

  onClickEditWithholding = (withholding: Withholding) => {
    this.editWithholdingForm = new WithholdingForm(withholding.AccountReceivableId);
    this.editWithholdingForm.updateForm(withholding);
    this.newWithholdingFormVisible = false;
    this.editWithholdingFormVisible = true;
  }

  updateWithholding = () => {
    if (!this.editWithholdingForm.isValid) {
      alert('Invalid form');
      return;
    }
    this.spinnerService.startSpinner();
    let withholding = this.editWithholdingForm.getObjectModel();
    this.withholdingService.updateWithholding(withholding)
      .pipe(switchMap(_ => this.loadAccountsReceivable()))
      .subscribe(_ => {
        this.editWithholdingFormVisible = false;
        this.loadWitholdings(this.selectedAccount)
      });
  }

  loadWorkHours = (account: AccountReceivableDetails) => {
    this.spinnerService.startSpinner();
    this.selectedAccount = account;
    this.newWorkHoursForm = null;
    this.editWorkHoursForm = null;
    this.workHoursService.getWorkHours(account.AccountReceivableId)
      .subscribe(rslt => {
        this.spinnerService.stopSpinner();
        this.workHours = rslt;
        this.workHoursPanelVisible = true;
      });
  }

  onClickAddWorkHours = () => {
    let id = this.selectedAccount.AccountReceivableId;
    this.newWorkHoursForm = new WorkHoursForm(id);
    this.newWorkHoursForm.visible = true;
  }

  addWorkHours = () => {
    if (!this.newWorkHoursForm.isValid) {
      alert('Invalid form');
      return;
    }
    this.spinnerService.startSpinner();
    let hours = this.newWorkHoursForm.getObjectModel();
    this.workHoursService.addWorkHours(hours)
      .pipe(switchMap(_ => this.loadAccountsReceivable()))
      .subscribe(_ => {
        this.newWorkHoursForm.visible = false;
        this.newWorkHoursForm = null;
        this.loadWorkHours(this.selectedAccount)
      });
  }

  onClickEditWorkHours = (hours: WorkHours) => {
    this.newWorkHoursForm = null;
    this.editWorkHoursForm = new WorkHoursForm(hours.AccountReceivableId);
    this.editWorkHoursForm.updateForm(hours);
    this.editWorkHoursForm.visible = true;
  }

  editWorkHours = () => {
    if (!this.editWorkHoursForm.isValid) {
      alert('Invalid form');
      return;
    }
    this.spinnerService.startSpinner();
    let hours = this.editWorkHoursForm.getObjectModel();
    this.workHoursService.updateWorkHours(hours)
      .pipe(switchMap(_ => this.loadAccountsReceivable()))
      .subscribe(_ => {
        this.editWorkHoursForm = null;
        this.loadWorkHours(this.selectedAccount)
      });
  }

}
