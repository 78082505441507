import { AccountPayableDetails } from '../models/account-payable-details';
import { DateFloor } from '../functions/date.functions';

import * as moment_ from 'moment';
const moment = moment_;

export class ExpenseProvider {

  constructor() {}

  getAverageMonthlyExpenses(account: AccountPayableDetails): number {
    let amount = account.AveragePaymentAmount || account.Amount;
    switch(account.IntervalType) {
      case 'Monthly': return amount;
      case 'Bi-Weekly': return amount * 2;
      case 'Weekly': return amount * 4;
    }
    return 0;
  }

  getAverageBiWeeklyExpenses(account: AccountPayableDetails): number {
    let amount = account.AveragePaymentAmount || account.Amount;
    switch(account.IntervalType) {
      case 'Monthly': return amount / 2;
      case 'Bi-Weekly': return amount;
      case 'Weekly': return amount * 2;
    }
    return 0;
  }

  getAverageWeeklyExpenses(account: AccountPayableDetails): number {
    let amount = account.AveragePaymentAmount || account.Amount;
    switch(account.IntervalType) {
      case 'Monthly': return amount / 4;
      case 'Bi-Weekly': return amount / 2;
      case 'Weekly': return amount;
    }
    return 0;
  }

  getNextPaymentDate(account: AccountPayableDetails): Date {
    let now = DateFloor(new Date());
    if (!!account.PendingPaymentDueDate) {
      let pending = moment(account.PendingPaymentDueDate).add('minute', 1);
      if (pending.isAfter(now)) return pending.toDate();
    }
    let date = moment(account.PendingPaymentDueDate || account.LastPaymentDueDate || account.StartDate).toDate();
    date.setFullYear(now.getFullYear(), now.getMonth());
    if (moment(now).isBefore(date)) return date;
    date = this.incrementPaycheckDate(date, account.IntervalType);
    while (moment(date).isBefore(now)) {
      date = this.incrementPaycheckDate(date, account.IntervalType);
    }
    return date;
  }

  incrementPaycheckDate(date: Date, intervalType: string, interval: number = 1) {
    switch(intervalType) {
      case 'Monthly': date.setMonth(date.getMonth() + (1 * interval)); break;
      case 'Bi-Weekly': date.setDate(date.getDate() + (14 * interval)); break;
      case 'Weekly': date.setDate(date.getDate() + (7 * interval)); break;
    }
    return date;
  }

}
