import * as moment_ from 'moment';
const moment = moment_;

import { DashboardDataModel } from '../dashboard.model';
import { ExpenseProvider } from 'src/shared/providers/expense.provider';
import { IncomeProvider } from 'src/shared/providers/income.provider';
import { Paycheck } from 'src/shared/models/paycheck';

export abstract class DashboardWidget {

  abstract model: DashboardDataModel;
  abstract paycheck: Paycheck;
  protected moment = moment;
  protected expenseProvider: ExpenseProvider;
  protected incomeProvider: IncomeProvider;

  constructor() {
    this.expenseProvider = new ExpenseProvider();
    this.incomeProvider = new IncomeProvider();
  }

  protected generateBlankChart = () => {
    return {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: []
        }
      ]
    }
  }

  pieChartOptions: any = {
    legend: {
      position: 'bottom'
    }
  };

  protected chartColors = [
    "#56e289",
    "#e256ae",
    "#68e256",
    "#cf56e2",
    "#aee256",
    "#8a56e2",
    "#e2cf56",
    "#5668e2",
    "#e28956",
    "#56aee2",
    "#e25668",
    "#56e2cf"
  ];

}
