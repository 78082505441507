import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { take, map, takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { ResourceService } from 'src/shared/services/resource.service';
import { BusinessEntity } from 'src/shared/models/business-entity.model';
import { BusinessEntityForm } from './business-entity.form';

@Component({
  selector: 'iots-business-entity-form',
  templateUrl: './business-entity-form.component.html',
  styleUrls: ['./business-entity-form.component.scss']
})
export class BusinessEntityFormComponent implements OnInit, OnDestroy {

  //INTERFACE
  @Input() businessEntity: BusinessEntity;
  @Output() businessEntityChange: EventEmitter<BusinessEntity>;
  @Output() next: EventEmitter<void>;
  @Output() cancel: EventEmitter<void>;
  //INTERNAL STATE
  destroy: Subject<boolean> = new Subject<boolean>();
  state: string;
  form: BusinessEntityForm;
  businessEntities: Observable<BusinessEntity[]>;
  privacyTypes: SelectItem[];
  industries: Observable<SelectItem[]>;
  countries: Observable<SelectItem[]>;

  constructor(private resourceService: ResourceService) {
    this.businessEntityChange = new EventEmitter();
    this.next = new EventEmitter();
    this.cancel = new EventEmitter();
    this.form = new BusinessEntityForm();
  }

  ngOnInit() {
    this.loadBusinessEntities();
    this.loadIndustries();
    this.loadCountries();
    this.privacyTypes = [
      { label: 'Public', value: 'Public' },
      { label: 'Private', value: 'Private' }
    ]
  }

  ngOnDestroy() {
    this.destroy.next(true);
  }

  private loadBusinessEntities = () => {
    this.businessEntities = this.resourceService.businessEntities
      .pipe(takeUntil(this.destroy));
  }

  private loadIndustries = () => {
    this.industries = this.resourceService.industries
      .pipe(
        take(1),
        map(i => i.map(x => { return { label: x.IndustryName, value: x} })),
        map(i => [{label: 'Please choose an industry...', value: null}].concat(i))
      )
  }

  private loadCountries = () => {
    this.countries = this.resourceService.countries
      .pipe(
        take(1),
        map(i => i.map(x => { return { label: x.CountryName, value: x} })),
        map(i => [{label: 'Please choose a country...', value: null}].concat(i))
      )
  }

  onClickAddBusinessEntity = () => {
    this.state = 'add-new-entity';
    this.form = new BusinessEntityForm();
    this.businessEntity = null;
  }

  selectBusinessEntity = (entity: BusinessEntity) => {
    this.businessEntity = entity;
    this.businessEntityChange.emit(entity);
  }

  onClickNext = () => {
    if (this.state == null) {
      if (!this.selectBusinessEntity) alert('Please choose a business entity')
      else this.next.emit();
      return;
    }
    if (!this.form.isValid) alert('Invalid Form.');
    this.resourceService.addBusinessEntity(this.form.getObjectModel())
      .subscribe(rslt => {
        this.businessEntity = rslt;
        this.businessEntityChange.emit(rslt);
        this.next.emit();
        this.state = null;
      })
  }

}
