import { AccountPayable } from 'src/shared/models/account-payable.model';

export class AccountPayableForm {
  accountPayableId: number;
  name: string;
  businessEntityId: number;
  paymentIntervalId: number;
  startDate: Date;
  amount: number;
  accountId: string;
  accountStatus: string;

  constructor() {
    this.accountStatus = 'Active';
  }

  updateForm(obj: any = null) {
    if (!obj) { this.accountStatus = 'Active'; return; }
    this.accountPayableId = obj.AccountPayableId;
    this.name = obj.Name;
    this.businessEntityId = obj.BusinessEntityId;
    this.paymentIntervalId = obj.PaymentIntervalId;
    this.startDate = new Date(obj.StartDate);
    this.amount = obj.Amount;
    this.accountId = obj.AccountId;
    this.accountStatus = obj.AccountStatus;
  }

  get isValid(): boolean {
    if (!this.name) return false;
    if (!this.businessEntityId) return false;
    if (!this.paymentIntervalId) return false;
    if (!this.startDate) return false;
    if (!this.accountStatus) return false;
    return true;
  }

  getObjectModel(): AccountPayable {
    let account = new AccountPayable();
    account.AccountPayableId = this.accountPayableId;
    account.BusinessEntityId = this.businessEntityId;
    account.PaymentIntervalId = this.paymentIntervalId;
    account.Name = this.name;
    account.StartDate = this.startDate;
    account.Amount = !this.amount ? 0 : this.amount;
    account.AccountId = this.accountId;
    account.AccountStatus = this.accountStatus;
    return account;
  }
}
