import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iots-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {

  destroy: Subject<boolean> = new Subject();
  menuItems: MenuItem[];
  selectedMenuItem: MenuItem;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.menuItems = [
      {label: 'Bills', icon: 'pi pi-calendar', command: this.onClickMenuItem },
      {label: 'Income', icon: 'pi pi-dollar', command: this.onClickMenuItem},
      {label: 'Budgets', icon: 'pi pi-sitemap', command: this.onClickMenuItem},
      {label: 'Goals', icon: 'pi pi-check', command: this.onClickMenuItem}
    ];
    this.route.fragment
      .pipe(takeUntil(this.destroy))
      .subscribe(frag => {
        let item = this.menuItems.find(i => i.label == frag);
        if (!item) this.selectedMenuItem = this.menuItems[0];
        else this.selectedMenuItem = item;
      });
  }

  ngOnDestroy() {

  }

  onClickMenuItem = (event: any) => {
    this.router.navigate(['/accounts'], { fragment: event.item.label });
  }

}
