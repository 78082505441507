import { AccountReceivableDetails } from '../models/account-receivable-details';
import { Paycheck } from '../models/paycheck';
import { IncomeProvider } from '../providers/income.provider';

import * as moment_ from 'moment';
const moment = moment_;

export class PaycheckFactory {

  private incomeProvider: IncomeProvider

  constructor() {
    this.incomeProvider = new IncomeProvider();
  }

  getCurrentPaycheck(account: AccountReceivableDetails): Paycheck {
    let dates: { date: Date, nextDate: Date };
    switch(account.IntervalType) {
      case 'Monthly': dates = this.getMonthlyPaycheck(account); break;
      case 'Bi-Weekly': dates = this.getBiWeeklyPaycheck(account); break;
      case 'Weekly': dates = this.getWeeklyPaycheck(account); break;
    }
    let paycheck = new Paycheck();
    paycheck.accountReceivableId = account.AccountReceivableId;
    paycheck.name = account.AccountName;
    paycheck.incomeType = account.ReceivableType;
    paycheck.paycheckDate = dates.date;
    paycheck.nextPaycheckDate = dates.nextDate;
    paycheck.amount = this.calculateIncome(account);
    let withholdings = this.calculateWithholdings(account);
    paycheck.netIncome = paycheck.amount - withholdings;
    return paycheck;
  }

  private calculateIncome(account: AccountReceivableDetails) {
    switch(account.IntervalType) {
      case 'Monthly': return this.incomeProvider.getMonthlyIncome(account);
      case 'Bi-Weekly': return this.incomeProvider.getBiWeeklyIncome(account);
      case 'Weekly': return this.incomeProvider.getWeeklyIncome(account);
    }
  }

  private calculateWithholdings(account: AccountReceivableDetails) {
    switch(account.IntervalType) {
      case 'Monthly': return account.getMonthlyWithholdings();
      case 'Bi-Weekly': return account.getBiWeeklyWithholdings();
      case 'Weekly': return account.getWeeklyWithholdings();
    }
  }

  private getMonthlyPaycheck(account: AccountReceivableDetails): { date: Date, nextDate: Date } {
    let now = moment(new Date());
    let first = moment(account.StartDate);
    let end = moment(first).add(1, 'month');
    while (end.isBefore(now)) end.add(1, 'month');
    let start = moment(end).subtract(1, 'month');
    return { date: start.toDate(), nextDate: end.toDate() };
  }

  private getBiWeeklyPaycheck(account: AccountReceivableDetails): { date: Date, nextDate: Date } {
    let now = moment(new Date());
    let first = moment(account.StartDate);
    let diff = now.diff(first, 'days');
    let start = now.subtract((diff % 14), 'days');
    let end = moment(start).add(14, 'days');
    return { date: start.toDate(), nextDate: end.toDate() };
  }

  private getWeeklyPaycheck(account: AccountReceivableDetails): { date: Date, nextDate: Date } {
    let now = moment(new Date());
    let first = moment(account.StartDate);
    let diff = now.diff(first, 'days');
    let start = now.subtract((diff % 7), 'days');
    let end = moment(start).add(7, 'days');
    return { date: start.toDate(), nextDate: end.toDate() };
  }

}
