import { BusinessEntity } from 'src/shared/models/business-entity.model';
import { AccountReceivable } from 'src/shared/models/account-receivable.model';

export class ManageIncomeForm {

  state: string;
  businessEntity: BusinessEntity;
  accountReceivable: AccountReceivable;

  constructor(data: any = null) {
    this.state = 'choose-entity';
    if (!data) return;
  }

  changeState = (state: string) => {
    this.state = state;
  }

}
