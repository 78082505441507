import * as moment_ from 'moment';
const moment = moment_;

export function DateFloor(date: Date, datePart: string = 'day') {
  date.setHours(0, 0, 0, 0);
  switch(datePart) {
    case 'day': break; //already done
    case 'month': date.setDate(1); break;
  }
  return date;
}

export function DateCeiling(date: Date, datePart: string = 'day') {
  date.setHours(23, 59, 59, 59);
  switch(datePart) {
    case 'day': break; //already done
    case 'month': date.setMonth(date.getMonth() + 1, 0); break;
  }
  return date;
}

export function DateSort<T>(array: T[], valueSelector: (v: T) => Date) {
  return array.sort((a: T, b: T) => {
    return moment(valueSelector(a)).toDate().getTime() -
      moment(valueSelector(b)).toDate().getTime();
  })
}

export function DateSortDescending<T>(array: T[], valueSelector: (v: T) => Date) {
  return array.sort((a: T, b: T) => {
    return moment(valueSelector(b)).toDate().getTime() -
      moment(valueSelector(a)).toDate().getTime();
  })
}
