import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { take, map, takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { ResourceService } from 'src/shared/services/resource.service';
import { AccountReceivableForm } from './account-receivable.form';
import { BusinessEntity } from 'src/shared/models/business-entity.model';
import { AccountService } from 'src/shared/services/account.service';


@Component({
  selector: 'iots-account-receivable-form',
  templateUrl: './account-receivable-form.component.html',
  styleUrls: ['./account-receivable-form.component.scss']
})
export class AccountReceivableFormComponent implements OnInit, OnDestroy {

  //INTERFACE
  @Input() editMode: boolean = false;
  _businessEntity: BusinessEntity;
  @Input() set businessEntity(val) {
    if (!val) return;
    this._businessEntity = val;
    this.form.businessEntityId = val.BusinessEntityId;
  }
  get businessEntity(): BusinessEntity { return this._businessEntity; }
  @Input() set account(val) { this.form.updateForm(val); }
  @Output() onComplete: EventEmitter<void>;
  @Output() previous: EventEmitter<void>;
  @Output() cancel: EventEmitter<void>;
  //INTERNAL STATE
  destroy: Subject<boolean> = new Subject<boolean>();
  form: AccountReceivableForm;
  businessEntities: Observable<SelectItem[]>;
  paymentIntervals: Observable<SelectItem[]>;
  receivableTypes: Observable<SelectItem[]>;
  accountStatusOptions: SelectItem[];

  constructor(private resourceService: ResourceService, private accountService: AccountService) {
    this.onComplete = new EventEmitter();
    this.previous = new EventEmitter();
    this.cancel = new EventEmitter();
    this.form = new AccountReceivableForm();
  }

  ngOnInit() {
    this.loadBusinessEntities();
    this.loadPaymentIntervals();
    this.loadReceivableTypes();
    this.accountStatusOptions = [
      { label: 'Active', value: 'Active' },
      { label: 'Suspended', value: 'Suspended' },
      { label: 'Inactive', value: 'Inactive' }
    ]
  }

  ngOnDestroy() {
    this.destroy.next(true);
  }

  private loadBusinessEntities = () => {
    this.businessEntities = this.resourceService.businessEntities
      .pipe(
        takeUntil(this.destroy),
        map(i => i.map(x => { return { label: x.Name, value: x.BusinessEntityId} }))
      );
  }

  private loadPaymentIntervals = () => {
    this.paymentIntervals = this.resourceService.paymentInterval
      .pipe(
        take(1),
        map(i => i.map(x => { return { label: x.IntervalType, value: x.PaymentIntervalId } })),
        map(i => [{label: 'Choose an payment interval...', value: null}].concat(i))
      );
  }

  private loadReceivableTypes = () => {
    this.receivableTypes = this.resourceService.receivableTypes
      .pipe(
        take(1),
        map(i => i.map(x => { return {
          label: x.AccountReceivableType,
          value: x.AccountReceivableTypeId
        }})),
        map(i => [{label: 'Choose an payment interval...', value: null}].concat(i))
      );
  }

  onClickComplete = () => {
    if (!this.form.isValid) alert('Invalid Form.');
    if (!this.editMode) {
      this.accountService.addAccountReceivable(this.form.getObjectModel())
        .subscribe(_ => this.onComplete.emit());
    } else {
      this.accountService.updateAccountReceivable(this.form.getObjectModel())
        .subscribe(_ => this.onComplete.emit());
    }
  }

}
