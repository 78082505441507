import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'iots-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  pathRoot: string = 'dashboard';

  constructor(
    private router: Router,
    private sidebarService: SidebarService) { }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.pathRoot = this.getPathRoot(event.urlAfterRedirects);
      })
  }

  toggleSidebar = () => {
    this.sidebarService.toggleVisibility();
  }

  private getPathRoot = (url: string) => {
    return url.split('/').filter(i => !!i)[0];
  }

}
