import { Component, Input } from '@angular/core';
import { DashboardWidget } from '../dashboard-widget';
import { DashboardDataModel } from '../../dashboard.model';
import { Paycheck } from 'src/shared/models/paycheck';
import { PaymentDetails } from 'src/shared/models/payment-details.model';

@Component({
  selector: 'iots-general-ledger-widget',
  templateUrl: './general-ledger.component.html',
  styleUrls: ['../../dashboard.component.scss']
})
export class GeneralLedgerWidget extends DashboardWidget {

  // ABSTRACT PROPERTIES
  @Input() paycheck: Paycheck;
  private _model: DashboardDataModel;
  get model(): DashboardDataModel { return this._model; };
  @Input() set model(value: DashboardDataModel) {
    if (!value) return;
    this._model = value;
    if (!!value.ledgerDetails && !!value.ledgerDetails.length)
      this.loadGeneralLedger(value);
  };
  // WIDGET PROPERTIES
  ledgerSidebarVisible: boolean = false;
  ledgerDetails: PaymentDetails[] = [];
  ledgerPreview: PaymentDetails[] = [];

  constructor() { super(); }

  private loadGeneralLedger = (model: DashboardDataModel) => {
    this.ledgerDetails = model.ledgerDetails;
    if (model.ledgerDetails.length > 5) {
      this.ledgerPreview = model.ledgerDetails.slice(0, 5);
    }
    else this.ledgerPreview = model.ledgerDetails;
  }

}
