import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';

import { FontAwesomeSharedModule } from 'src/shared/modules/font-awesome.module';
import { ExpenseChartWidget } from './expense-chart/expense-chart.component';
import { IncomeChartWidget } from './income-chart/income-chart.component';
import { PaycheckWidget } from './paycheck/paycheck.component';
import { PendingPaymentsWidget } from './pending-payments/pending-payments.component';
import { GeneralLedgerWidget } from './general-ledger/general-ledger.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeSharedModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    ChartModule,
    TableModule,
    SidebarModule
  ],
  declarations: [
    ExpenseChartWidget,
    IncomeChartWidget,
    PaycheckWidget,
    PendingPaymentsWidget,
    GeneralLedgerWidget
  ],
  exports: [
    ExpenseChartWidget,
    IncomeChartWidget,
    PaycheckWidget,
    PendingPaymentsWidget,
    GeneralLedgerWidget
  ]
})
export class DashboardWidgetModule { }
