import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SidebarService {

  private _isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get isVisible(): Observable<boolean> {
    return this._isVisible.asObservable();
  }

  get isVisibleSync(): boolean {
    return this._isVisible.value;
  }

  toggleVisibility = () => {
    this._isVisible.next(!this._isVisible.value);
  }

  setVisibility = (visible: boolean) => {
    this._isVisible.next(visible);
  }

}
