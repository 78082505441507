import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ConfigService } from 'shaman-utils';
import { WorkHours } from '../models/work-hours.model';

@Injectable()
export class WorkHoursService extends ApiService {

  protected apiBaseUri: Observable<string>;

  constructor(httpClient: HttpClient, configService: ConfigService<any>) {
    super(httpClient);
    this.apiBaseUri = configService.currentConfiguration.pipe(map(config => config.apiBaseUri));
  }

  getWorkHours = (accountId: number): Observable<WorkHours[]> => {
    return this.get(`/work-hours/${accountId}`)
      .pipe(map(data => {
        return data.sort((a: WorkHours, b: WorkHours) => a.PayDate < b.PayDate ? 1 : -1);
      }));
  }

  addWorkHours = (workHours: WorkHours): Observable<void> => {
    return this.put('/work-hours', workHours);
  }

  updateWorkHours = (workHours: WorkHours): Observable<void> => {
    return this.post(`/work-hours`, workHours);
  }

}
