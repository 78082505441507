import { WorkHours } from 'src/shared/models/work-hours.model';

import * as moment_ from 'moment';
const moment = moment_;

export class WorkHoursForm {

  private _accountId: number;
  get accountId(): number { return this._accountId; }
  visible: boolean = false;
  workHoursId: number;
  payDate: Date;
  totalHours: number;

  constructor(accountId: number) {
    this._accountId = accountId;
  }

  updateForm(obj: WorkHours = null) {
    if (!obj) return;
    this.workHoursId = obj.WorkHoursId;
    this.payDate = moment(obj.PayDate).toDate();
    this.totalHours = obj.TotalHours;
  }

  get isValid(): boolean {
    if (!this.payDate) return false;
    if (!this.totalHours) return false;
    return true;
  }

  getObjectModel(): WorkHours {
    let workHours = new WorkHours();
    workHours.WorkHoursId = this.workHoursId;
    workHours.AccountReceivableId = this._accountId;
    workHours.PayDate = this.payDate;
    workHours.TotalHours = this.totalHours;
    return workHours;
  }

}
