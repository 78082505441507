import { IncomeTypeProvider } from '../income-type-provider.interface';
import { AccountReceivableDetails } from 'src/shared/models/account-receivable-details';

export class HourlyWageProvider implements IncomeTypeProvider {

  get receivableType(): string { return 'Hourly Wage'; };

  getMonthlyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    let hours = account.AverageTotalHours || account.LastTotalHours;
    if (!hours || hours == 0) return 0;
    // BASED ON PAYCHECK HEURISTICS, NOT MATHEMATIC DEFINITIONS OF TIME
    switch(account.IntervalType) {
      case 'Monthly': return account.Amount * hours;
      case 'Bi-Weekly': return (account.Amount * hours) * 2;
      case 'Weekly': return (account.Amount * hours) * 4;
    }
    return 0;
  }

  getBiWeeklyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    let hours = account.AverageTotalHours || account.LastTotalHours;
    if (!hours || hours == 0) return 0;
    // BASED ON PAYCHECK HEURISTICS, NOT MATHEMATIC DEFINITIONS OF TIME
    switch(account.IntervalType) {
      case 'Monthly': return (account.Amount * hours) / 2;
      case 'Bi-Weekly': return (account.Amount * hours);
      case 'Weekly': return (account.Amount * hours) * 2;
    }
    return 0;
  }

  getWeeklyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    let hours = account.AverageTotalHours || account.LastTotalHours;
    if (!hours || hours == 0) return 0;
    // BASED ON PAYCHECK HEURISTICS, NOT MATHEMATIC DEFINITIONS OF TIME
    switch(account.IntervalType) {
      case 'Monthly': return (account.Amount * hours) / 4;
      case 'Bi-Weekly': return (account.Amount * hours) / 2;
      case 'Weekly': return (account.Amount * hours);
    }
    return 0;
  }

}
