import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ConfigService, CONFIGURATION_FILE_PATH } from 'shaman-utils';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    { provide: CONFIGURATION_FILE_PATH, useValue: '/assets/data/client-config.json' },
    ConfigService
  ],
  exports: []
})
export class ConfigModule { }
