import { AccountReceivable } from 'src/shared/models/account-receivable.model';

export class AccountReceivableForm {
  accountReceivableId: number;
  name: string;
  businessEntityId: number;
  paymentIntervalId: number;
  receivableTypeId: number;
  startDate: Date;
  amount: number;
  accountStatus: string;

  constructor() {
    this.accountStatus = 'Active';
  }

  updateForm(obj: any = null) {
    if (!obj) { this.accountStatus = 'Active'; return; }
    this.accountReceivableId = obj.AccountReceivableId;
    this.name = obj.Name;
    this.businessEntityId = obj.BusinessEntityId;
    this.paymentIntervalId = obj.PaymentIntervalId;
    this.receivableTypeId = obj.AccountReceivableTypeId;
    this.startDate = new Date(obj.StartDate);
    this.amount = obj.Amount;
    this.accountStatus = obj.AccountStatus;
  }

  get isValid(): boolean {
    if (!this.name) return false;
    if (!this.businessEntityId) return false;
    if (!this.paymentIntervalId) return false;
    if (!this.receivableTypeId) return false;
    if (!this.startDate) return false;
    if (!this.accountStatus) return false;
    return true;
  }

  getObjectModel(): AccountReceivable {
    let account = new AccountReceivable();
    account.AccountReceivableId = this.accountReceivableId;
    account.BusinessEntityId = this.businessEntityId;
    account.PaymentIntervalId = this.paymentIntervalId;
    account.AccountReceivableTypeId = this.receivableTypeId;
    account.Name = this.name;
    account.StartDate = this.startDate;
    account.Amount = !this.amount ? 0 : this.amount;
    account.AccountStatus = this.accountStatus;
    return account;
  }
}
