import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeSharedModule } from '../shared/modules/font-awesome.module';
import { NavigationModule } from '../shared/modules/navigation.module';
import { AccountsModule } from './accounts/accounts.module';
import { SettingsComponent } from './settings/settings.component';
import { ConfigModule } from '../shared/modules/config.module';
import { LoginModule } from './login/login.module';
import { ShamanAuthModule, AuthInterceptor } from 'shaman-auth';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeSharedModule,
    NavigationModule,
    ToastModule,
    ConfigModule,
    LoginModule,
    ShamanAuthModule,
    AccountsModule,
    DashboardModule
  ],
  providers: [
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
