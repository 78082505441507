export class BusinessEntity {
  BusinessEntityId: number;
  IndustryId: number;
  Industry?: any;
  EntityType: string;
  Name: string;
  Phone: string;
  Website: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Country: string;
  ZipCode: string;
  CreatedBy: string;
  CreatedDate: Date;
  ModifiedBy: string;
  ModifiedDate: Date;
}
