export class AccountPayable {
  AccountPayableId: number;
  BusinessEntityId: number;
  PaymentIntervalId: number;
  EmailAddress: string;
  AccountId: string;
  Name: string;
  AccountStatus: string;
  StartDate: Date;
  Amount: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
}
