import { Component, Input } from '@angular/core';
import { DashboardWidget } from '../dashboard-widget';
import { DashboardDataModel } from '../../dashboard.model';
import { Paycheck } from 'src/shared/models/paycheck';

@Component({
  selector: 'iots-expense-chart-widget',
  templateUrl: './expense-chart.component.html',
  styles: []
})
export class ExpenseChartWidget extends DashboardWidget {

  // ABSTRACT PROPERTIES
  @Input() paycheck: Paycheck;
  private _model: DashboardDataModel;
  get model(): DashboardDataModel { return this._model; };
  @Input() set model(value: DashboardDataModel) {
    if (!value) return;
    this._model = value;
    this.loadExpensePieChart(value);
  };
  // WIDGET PROPERTIES
  chartData: any;

  constructor() { super(); }

  private loadExpensePieChart = (model: DashboardDataModel) => {
    if (!model.expenseDetails) return 0;
    let industryMap = model.expenseDetails
      .map(p => p.IndustryName)
      .reduce((a, b) => { a[b] = 0; return a; }, {});

    let industries = Object.keys(industryMap).sort();
    for (var i = 0; i < model.expenseDetails.length; i++) {
      let act = model.expenseDetails[i];
      let rslt = this.expenseProvider.getAverageMonthlyExpenses(act);
      industryMap[act.IndustryName] += rslt;
    }
    let colorCount = 0;
    let chartData = this.generateBlankChart();
    for (var i = 0; i < industries.length; i++) {
      chartData.labels.push(industries[i]);
      chartData.datasets[0].data.push(industryMap[industries[i]]);
      chartData.datasets[0].backgroundColor.push(this.chartColors[colorCount]);
      colorCount = (colorCount + 1) % this.chartColors.length;
    }
    this.chartData = chartData;
  }

}
