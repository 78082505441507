import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import { LoginComponent } from './login.component';
import { FontAwesomeSharedModule } from '../../shared/modules/font-awesome.module';
import { ShamanAuthModule } from 'shaman-auth';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeSharedModule,
    InputTextModule,
    ButtonModule,
    ShamanAuthModule
  ],
  declarations: [LoginComponent]
})
export class LoginModule { }
