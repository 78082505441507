import { ExpenseProvider } from '../providers/expense.provider';
import { DateFloor, DateCeiling } from '../functions/date.functions';
import { Paycheck } from './paycheck';

import * as moment_ from 'moment';
const moment = moment_;

export class AccountPayableDetails {

  private expenseProvider: ExpenseProvider;
  AccountPayableId: number;
  EmailAddress: string;
  AccountName: string;
  BusinessEntityId: number;
  BusinessEntityName: string;
  AccountId: string;
  IndustryName: string;
  EntityType: string;
  IntervalType: string;
  StartDate: Date;
  LastPaymentDueDate: Date;
  LastPaymentPostedDate: Date;
  LastPaymentAmount: number;
  PendingPaymentDueDate: Date;
  AveragePaymentAmount: number;
  Amount: number;
  Website: string;
  AccountStatus: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
  ModifiedDate: Date;
  // COMPUTED VALUES
  billDue: Date;
  // INTERIM VALUES
  paycheckDate: Date;

  constructor(obj: any) {
    this.expenseProvider = new ExpenseProvider();
    if (!obj) return;
    Object.assign(this, obj);
    this.billDue = this.nextDueDate();
  }

  private nextDueDate(): Date {
    let next = this.expenseProvider.getNextPaymentDate(this);
    return moment(next).toDate();
  }

  get billDueDisplay(): string {
    let date = moment(this.billDue || new Date());
    let value = `${date.format("MMMM")} ${date.format("Do")}`;
    if (this.IndustryName != "Utilities") return value;
    else if (!!this.PendingPaymentDueDate) return value;
    else return `${value} (approx.)`;
  }

  getPaymentForPaycheck(paycheck: Paycheck) {
    let start = moment(DateFloor(paycheck.paycheckDate));
    let end = moment(DateCeiling(paycheck.nextPaycheckDate));
    if (!!this.PendingPaymentDueDate) {
      if (moment(this.PendingPaymentDueDate).isBetween(start, end)) {
        return moment(this.PendingPaymentDueDate).toDate();
      }
    }
    if (!!this.LastPaymentDueDate) {
      if (moment(this.LastPaymentDueDate).isBetween(start, end)) {
        return moment(this.LastPaymentDueDate).toDate();
      }
    }
    let date = moment(this.LastPaymentDueDate || this.StartDate).toDate();
    if (moment(date).isBefore(start)) return date;
    date = this.expenseProvider.incrementPaycheckDate(date, this.IntervalType);
    while (moment(date).isBefore(start)) {
      date = this.expenseProvider.incrementPaycheckDate(date, this.IntervalType);
    }
    return date;
  }

  get missingPayments(): boolean {
    if (moment(this.PendingPaymentDueDate).isBefore(moment(new Date()))) return true;
    let payments: Date[] = [
      moment(this.LastPaymentDueDate).toDate(),
      moment(this.PendingPaymentDueDate || this.LastPaymentDueDate).toDate()
    ];
    let billDue = moment(this.billDue);
    let lastPayment = moment(Math.max(...payments.map(p => p.getTime())));
    const daysSinceLastPayment = billDue.diff(lastPayment, 'days');
    if (daysSinceLastPayment > 31) return true;
    return false;
  }
}
