import { AccountReceivableDetails } from 'src/shared/models/account-receivable-details';
import { IncomeProvider } from 'src/shared/providers/income.provider';
import { AccountPayableDetails } from 'src/shared/models/account-payable-details';
import { PaymentDetails } from 'src/shared/models/payment-details.model';

export class DashboardDataModel {

  private incomeProvider: IncomeProvider;
  expenseDetails: AccountPayableDetails[];
  incomeDetails: AccountReceivableDetails[];
  ledgerDetails: PaymentDetails[];

  constructor(obj: any = null) {
    this.incomeProvider = new IncomeProvider();
    if (!obj) return;
    Object.assign(this, obj);
  }

  get hasIncome(): boolean {
    return !!this.incomeDetails && this.incomeDetails.length > 0;
  }
  get hasExpenses(): boolean {
    return !!this.expenseDetails && this.expenseDetails.length > 0;
  }

  get averageMonthlyExpenses(): number {
    if (!this.expenseDetails) return 0;
    return this.expenseDetails.reduce((a, b) => {
      let itemTotal = b.AveragePaymentAmount || b.Amount;
      if (b.IntervalType == 'Bi-Weekly') itemTotal *= 2.17;
      else if (b.IntervalType == 'Weekly') itemTotal *= 4.34;
      return a + itemTotal;
    }, 0)
  }

  get totalMonthlyIncome(): number {
    if (!this.incomeDetails) return 0;
    return this.incomeDetails.reduce((a, b) => {
      return a + this.incomeProvider.getMonthlyIncome(b);
    }, 0);
  }

}
