import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { ApiService, ConfigService } from 'shaman-utils';
import { Observable } from 'rxjs';
import { AccountPayable } from '../models/account-payable.model';
import { AccountReceivable } from '../models/account-receivable.model';
import { AccountPayableDetails } from '../models/account-payable-details';
import { AccountReceivableDetails } from '../models/account-receivable-details';

@Injectable()
export class AccountService extends ApiService {

  protected apiBaseUri: Observable<string>;

  constructor(httpClient: HttpClient, configService: ConfigService<any>) {
    super(httpClient);
    this.apiBaseUri = configService.currentConfiguration.pipe(map(config => config.apiBaseUri));
  }

  // ACCOUNTS PAYABLE
  getAccountsPayable = (): Observable<AccountPayable[]> => {
    return this.get('/accounts/payable');
  }

  getAccountPayable = (id: number): Observable<AccountPayable> => {
    return this.get(`/accounts/payable/${id}`);
  }

  addAccountPayable = (account: AccountPayable) => {
    return this.put('/accounts/payable', account);
  }

  updateAccountPayable = (account: AccountPayable) => {
    return this.post('/accounts/payable', account);
  }

  getAccountPayableDetails = (): Observable<AccountPayableDetails[]> => {
    return this.get('/accounts/payable/views/details')
      .pipe(map(data => {
        return data.sort((a, b) => a.AccountName < b.AccountName ? -1 : 1);
      }))
      .pipe(map(rslt => rslt.map(x => new AccountPayableDetails(x))));
  }

  // ACCOUNTS RECEIVABLE
  getAccountsReceivable = (): Observable<AccountReceivable[]> => {
    return this.get('/accounts/receivable');
  }

  getAccountReceivable = (id: number): Observable<AccountReceivable> => {
    return this.get(`/accounts/receivable/${id}`);
  }

  addAccountReceivable = (account: AccountReceivable) => {
    return this.put('/accounts/receivable', account);
  }

  updateAccountReceivable = (account: AccountReceivable) => {
    return this.post('/accounts/receivable', account);
  }

  getAccountReceivableDetails = (): Observable<AccountReceivableDetails[]> => {
    return this.get('/accounts/receivable/views/details')
      .pipe(map(data => {
        return data.sort((a, b) => a.AccountName < b.AccountName ? -1 : 1);
      }))
      .pipe(map(rslt => rslt.map(x => new AccountReceivableDetails(x))));
  }

}

