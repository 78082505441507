import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SidebarService } from './sidebar.service';

@Component({
  selector: 'iots-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isVisible: boolean = false;
  menuItems: SidebarMenuItem[] = [];

  constructor(private sidebarService: SidebarService, private router: Router) { }

  ngOnInit() {
    this.sidebarService.isVisible.subscribe(val => this.isVisible = val);
    this.menuItems = [
      { label: 'Dashboard', icon: ['fas', 'tachometer-alt'], command: () => this.onClickLink(['/dashboard']) },
      { label: 'Accounts', icon: ['fas', 'file-invoice-dollar'], command: () => this.onClickLink(['/accounts']) },
      { label: 'Settings', icon: ['fas', 'user-cog'], command: () => this.onClickLink(['/settings']) }
    ]
  }

  onSidebarHide = () => {
    this.sidebarService.setVisibility(false);
  }

  onClickLink = (link: string[]) => {
    this.router.navigate(link);
    this.sidebarService.setVisibility(false);
  }

}

export interface SidebarMenuItem {
  label: string;
  icon?: string[];
  command?: (event?: any) => void;
  items?: SidebarMenuItem[];
}
