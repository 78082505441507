import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { DashboardDataModel } from './dashboard.model';
import { SpinnerService } from 'src/shared/layout/spinner/spinner.service';
import { Paycheck } from 'src/shared/models/paycheck';
import { PaycheckFactory } from 'src/shared/factories/paycheck.factory';

@Component({
  selector: 'iots-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  private paycheckFactory: PaycheckFactory;
  model: DashboardDataModel;
  paychecks: Paycheck[];

  constructor(
    private dashboardService: DashboardService,
    private spinnerService: SpinnerService) {
      this.paycheckFactory = new PaycheckFactory();
    }

  ngOnInit() {
    this.spinnerService.startSpinner();
    this.dashboardService.getDashboardData()
      .subscribe(rslt => {
        this.model = rslt;
        this.loadPaychecks();
        this.spinnerService.stopSpinner();
      });
  }

  loadPaychecks = () => {
    if (!this.model.incomeDetails) return;
    this.paychecks = this.model.incomeDetails
      .filter(x => x.ReceivableType != 'Hourly Wage' || x.LastPayDate != null)
      .map(x => this.paycheckFactory.getCurrentPaycheck(x))
      .filter(p => {
        if (p.incomeType == 'Annual Salary') return true;
        if (p.incomeType == 'Hourly Wage') return true;
        return false;
      })
  }

}
