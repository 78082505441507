import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { AccountService } from '../../../shared/services/account.service';
import { ResourceService } from '../../../shared/services/resource.service';
import { SpinnerService } from '../../../shared/layout/spinner/spinner.service';
import { AccountPayableDetails } from 'src/shared/models/account-payable-details';
import { ManageBillForm } from './manage-bill.form';
import { AccountPayable } from 'src/shared/models/account-payable.model';
import { BusinessEntity } from 'src/shared/models/business-entity.model';
import { PaymentService } from 'src/shared/services/payment.service';
import { PaymentForm } from './payment.form';
import { Payment } from 'src/shared/models/payment.model';
import { ExpenseByMonthReport } from '../reports/expense-by-month/expense-by-month.component';

@Component({
  selector: 'iots-manage-bills',
  templateUrl: './manage-bills.component.html',
  styleUrls: ['./manage-bills.component.scss']
})
export class ManageBillsComponent implements OnInit {

  accounts: AccountPayableDetails[] = [];
  billForm: ManageBillForm;
  billFormVisible: boolean = false;
  editBillForm: ManageBillForm;
  editBillFormVisible: boolean = false;
  selectedAccount: AccountPayableDetails;
  paymentsVisible: boolean = false;
  paymentDetails: any[];
  newPaymentForm: PaymentForm;
  newPaymentFormVisible: boolean = false;
  editPaymentForm: PaymentForm;
  editPaymentFormVisible: boolean = false;
  @ViewChild(ExpenseByMonthReport, {static: false})
  expenseByMonthReport: ExpenseByMonthReport;

  constructor(
    private spinnerService: SpinnerService,
    private accountService: AccountService,
    private paymentService: PaymentService,
    private resourceService: ResourceService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.spinnerService.startSpinner()
    this.loadAccountsPayable().subscribe(_ => this.spinnerService.stopSpinner());
  }

  loadAccountsPayable = ()=> {
    return this.accountService.getAccountPayableDetails()
      .pipe(tap(rslt => this.accounts = rslt));
  }

  onClickAddBill = () => {
    this.billForm = new ManageBillForm();
    this.billFormVisible = true;
  }

  onClickCloseBillForm = () => {
    this.billForm = null;
    this.billFormVisible = false;
  }

  onBusinessEntitySelected = () => {
    this.billForm.changeState('create-account');
  }

  onClickPrevious = () => {
    this.billForm.changeState('choose-entity');
  }

  onCompleteAddBill = () => {
    this.billForm = null;
    this.billFormVisible = false;
    this.spinnerService.startSpinner();
    this.loadAccountsPayable().subscribe(_ => this.spinnerService.stopSpinner());
  }

  onClickEditBill = (bill: AccountPayableDetails) => {
    let observable = combineLatest(
      this.resourceService.getBusinessEntity(bill.BusinessEntityId),
      this.accountService.getAccountPayable(bill.AccountPayableId),
      (entity: BusinessEntity, account: AccountPayable) => ({entity, account})
    );
    observable.subscribe(rslt => {
      this.editBillForm = new ManageBillForm();
      this.editBillForm.accountPayable = rslt.account;
      this.editBillForm.businessEntity = rslt.entity;
      this.editBillFormVisible = true;
    });
  }

  onClickCloseEditBillForm = () => {
    this.editBillForm = null;
    this.editBillFormVisible = false;
  }

  onEditBusinessEntitySelected = () => {
    this.editBillForm.changeState('create-account');
  }

  onClickEditPrevious = () => {
    this.editBillForm.changeState('choose-entity');
  }

  onCompleteEditBill = () => {
    this.editBillForm = null;
    this.editBillFormVisible = false;
    this.spinnerService.startSpinner();
    this.loadAccountsPayable().subscribe(_ => this.spinnerService.stopSpinner());
  }

  loadPayments = (account: AccountPayableDetails) => {
    this.spinnerService.startSpinner();
    this.selectedAccount = account;
    this.newPaymentFormVisible = false;
    this.newPaymentForm = new PaymentForm('payable', account.AccountPayableId);
    this.paymentService.getPayablePayments(account.AccountPayableId)
      .subscribe(rslt => {
        this.spinnerService.stopSpinner();
        this.paymentDetails = rslt;
        this.paymentsVisible = true;
      });
  }

  getAccountOptions(act: AccountPayableDetails): MenuItem[] {
    let options: MenuItem[] = [
      { label: 'Manage Payments', icon: 'pi pi-eye', command: () => this.loadPayments(act)},
      { label: 'Total by Month', icon: 'pi pi-chart-bar', command: () => this.loadExpenseByMonthReport(act) },
      {
        label: 'Visit Website',
        icon: 'pi pi-globe',
        command: () => { window.open(act.Website); },
        disabled: !act.Website
      }
    ];
    return options;
  }

  onClickAddPayment = () => {
    let id = this.selectedAccount.AccountPayableId;
    this.newPaymentForm = new PaymentForm('payable', id);
    //TODO: CONSIDER REMOVING THIS RESTRICTION
    if (this.selectedAccount.IndustryName != "Utilities") {
      this.newPaymentForm.amount = this.selectedAccount.Amount;
      this.newPaymentForm.dueDate = this.selectedAccount.billDue;
    }
    this.editPaymentFormVisible = false;
    this.newPaymentFormVisible = true;
  }

  addPayment = () => {
    if (!this.newPaymentForm.isValid) {
      alert('Invalid form');
      return;
    }
    this.spinnerService.startSpinner();
    let payment = this.newPaymentForm.getObjectModel();
    this.paymentService.addPayment(payment)
      .pipe(switchMap(_ => this.loadAccountsPayable()))
      .subscribe(_ => {
        this.newPaymentFormVisible = false;
        this.newPaymentForm = new PaymentForm('payable', this.selectedAccount.AccountPayableId);
        this.loadPayments(this.selectedAccount)
      });
  }

  onClickEditPayment = (payment: Payment) => {
    this.editPaymentForm = new PaymentForm('payable', payment.AccountId);
    this.editPaymentForm.updateForm(payment);
    this.newPaymentFormVisible = false;
    this.editPaymentFormVisible = true;
  }

  onCheckPaymentPaid = (checked: boolean) => {
    if (!checked) this.editPaymentForm.postedDate = null;
  }

  updatePayment = () => {
    if (!this.editPaymentForm.isValid) {
      alert('Invalid form');
      return;
    }
    this.spinnerService.startSpinner();
    let payment = this.editPaymentForm.getObjectModel();
    this.paymentService.updatePayment(payment)
      .pipe(switchMap(_ => this.loadAccountsPayable()))
      .subscribe(_ => {
        this.editPaymentFormVisible = false;
        this.loadPayments(this.selectedAccount)
      });
  }

  loadExpenseByMonthReport = (account: AccountPayableDetails) => {
    this.expenseByMonthReport.showReport(account);
  }

  missingPaymentWarning = () => {
    this.messageService.add({
      severity:'warn',
      summary:'Missing Payment Data',
      detail:'Your payment history is not up to date, Click Options -> Manage Payments to add missing payments.',
      life: 6000
    });
  }

}
