import { Withholding } from 'src/shared/models/withholding.model';

export class WithholdingForm {

  private _accountId: number;
  get accountId(): number { return this._accountId; }
  withholdingId: number;
  name: string;
  rate: number;
  rateIndicator: string;

  constructor(accountId: number) {
    this._accountId = accountId;
    this.rateIndicator = '%';
  }

  updateForm(obj: Withholding = null) {
    if (!obj) return;
    this.withholdingId = obj.WithholdingId;
    this.name = obj.WithholdingName;
    this.rate = obj.Rate;
    this.rateIndicator = obj.RateIndicator;
  }

  get isValid(): boolean {
    if (this.rateIndicator != '%' && this.rateIndicator != '$') return false;
    if (!this.name) return false;
    if (!this.rate) return false;
    return true;
  }

  getObjectModel(): Withholding {
    let withholding = new Withholding();
    withholding.WithholdingId = this.withholdingId;
    withholding.AccountReceivableId = this._accountId;
    withholding.WithholdingName = this.name;
    withholding.Rate = this.rate;
    withholding.RateIndicator = this.rateIndicator;
    return withholding;
  }
}
