import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ConfigService } from 'shaman-utils';
import { Withholding } from '../models/withholding.model';

@Injectable()
export class WithholdingService extends ApiService {

  protected apiBaseUri: Observable<string>;

  constructor(httpClient: HttpClient, configService: ConfigService<any>) {
    super(httpClient);
    this.apiBaseUri = configService.currentConfiguration.pipe(map(config => config.apiBaseUri));
  }

  getWithholdings = (accountId: number): Observable<Withholding[]> => {
    return this.get(`/withholdings/${accountId}`)
      .pipe(map(data => {
        return data.sort((a: Withholding, b: Withholding) => a.CreatedDate < b.CreatedDate ? 1 : -1);
      }));
  }

  addWithholding = (withholding: Withholding): Observable<void> => {
    return this.put('/withholdings', withholding);
  }

  updateWithholding = (withholding: Withholding): Observable<void> => {
    return this.post(`/withholdings`, withholding);
  }

}
