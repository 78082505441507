import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap, tap, take } from 'rxjs/operators';
import { ApiService, ConfigService } from 'shaman-utils';
import { Observable } from 'rxjs';
import { BusinessEntity } from '../models/business-entity.model';
import { Industry } from '../models/industry.model';
import { Country } from '../models/country.model';
import { PaymentInterval } from '../models/payment-interval.model';
import { AccountReceivableType } from '../models/account-receivable-type.model';
import { Withholding } from '../models/withholding.model';

@Injectable()
export class ResourceService extends ApiService {

  protected apiBaseUri: Observable<string>;
  private businessEntitySubject: ReplaySubject<BusinessEntity[]> = new ReplaySubject(1);
  _businessEntities: Observable<BusinessEntity[]> = this.businessEntitySubject.asObservable();
  private industrySubject: ReplaySubject<Industry[]> = new ReplaySubject(1);
  industries: Observable<Industry[]> = this.industrySubject.asObservable();
  private countrySubject: ReplaySubject<Country[]> = new ReplaySubject(1);
  countries: Observable<Country[]> = this.countrySubject.asObservable();
  private paymentIntervalSubject: ReplaySubject<PaymentInterval[]> = new ReplaySubject(1);
  paymentInterval: Observable<PaymentInterval[]> = this.paymentIntervalSubject.asObservable();
  private receivableTypesSubject: ReplaySubject<AccountReceivableType[]> = new ReplaySubject(1);
  receivableTypes: Observable<AccountReceivableType[]> = this.receivableTypesSubject.asObservable();

  constructor(httpClient: HttpClient, configService: ConfigService<any>) {
    super(httpClient);
    this.apiBaseUri = configService.currentConfiguration.pipe(map(config => config.apiBaseUri));
    this.loadBusinessEntities();
    this.loadIndustries();
    this.loadCountries();
    this.loadPaymentIntervals();
    this.loadReceivableTypes();
  }

  loadBusinessEntities = () => {
    this.get('/business-entities')
      .pipe(map(data => {
        return data.sort((a, b) => a.Name < b.Name ? -1 : 1);
      }))
      .subscribe(rslt => this.businessEntitySubject.next(rslt));
  }

  loadIndustries = () => {
    this.get('/industry')
      .pipe(map(data => {
        return data.sort((a, b) => a.IndustryName < b.IndustryName ? -1 : 1);
      }))
      .subscribe(rslt => this.industrySubject.next(rslt));
  }

  loadCountries = () => {
    this.get('/country')
      .pipe(map((countries: Country[]) => {
        countries = countries.sort((a, b) => a.CountryName < b.CountryName ? -1 : 1)
        let usa = countries.find(c => c.CountryName == 'United States of America (USA)');
        countries = countries.filter(c => c.CountryId != usa.CountryId);
        return [usa].concat(countries);
      }))
      .subscribe(rslt => this.countrySubject.next(rslt));
  }

  loadPaymentIntervals = () => {
    this.get('/payments/intervals').subscribe(rslt => this.paymentIntervalSubject.next(rslt));
  }

  loadReceivableTypes = () => {
    this.get('/accounts/receivable/types').subscribe(rslt => this.receivableTypesSubject.next(rslt));
  }

  get businessEntities(): Observable<BusinessEntity[]> {
    let observable = combineLatest(
      this._businessEntities,
      this.industries,
      (entities: BusinessEntity[], industries: Industry[]) => ({entities, industries})
    )
    return observable.pipe(map(rslt => {
      let industryMap = rslt.industries.reduce((a, b) => { a[b.IndustryId] = b; return a; }, {});
      return rslt.entities.map(entity => {
        let industry = industryMap[entity.IndustryId];
        if (!!industry) entity.Industry = industry;
        return entity;
      })
    }));
  }

  addBusinessEntity = (entity: BusinessEntity): Observable<BusinessEntity> => {
    return this.put('/business-entities', entity)
      .pipe(
        tap(rslt => this.updateBusinessEntities(entity, rslt.id)),
        map(rslt => {
          entity.BusinessEntityId = rslt.id;
          return entity;
        })
      );
  }

  private updateBusinessEntities = (entity: BusinessEntity, id: number) => {
    this._businessEntities.pipe(take(1)).subscribe(rslt => {
      entity.BusinessEntityId = id;
      this.businessEntitySubject.next(rslt.concat([entity]));
    });
  }

  getBusinessEntity = (id: number): Observable<BusinessEntity> => {
    return this.get<BusinessEntity>(`/business-entities/${id}`)
  }

}
