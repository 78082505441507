import { IncomeTypeProvider } from '../income-type-provider.interface';
import { AccountReceivableDetails } from 'src/shared/models/account-receivable-details';

export class AnnualSalaryProvider implements IncomeTypeProvider {

  get receivableType(): string { return 'Annual Salary'; };

  getMonthlyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    return account.Amount / 12;
  }

  getBiWeeklyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    return account.Amount / 26;
  }

  getWeeklyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    return account.Amount / 52;
  }

}
