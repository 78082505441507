import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

import { AccountPayableDetails } from 'src/shared/models/account-payable-details';
import { PaymentService } from 'src/shared/services/payment.service';
import { SpinnerService } from 'src/shared/layout/spinner/spinner.service';
import { Payment } from 'src/shared/models/payment.model';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'iots-expense-by-month-report',
  templateUrl: './expense-by-month.component.html',
  styleUrls: ['./expense-by-month.component.scss']
})
export class ExpenseByMonthReport implements OnInit {

  account: AccountPayableDetails;
  visible: boolean = false;
  chartData: any;
  barChartOptions: any;

  constructor(
    private paymentService: PaymentService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.setBarChartOptions();
  }

  showReport = (account: AccountPayableDetails) => {
    this.account = account;
    this.spinnerService.startSpinner();
    this.paymentService.getPayablePayments(account.AccountPayableId)
      .pipe(map(this.getTotalsByMonth))
      .pipe(map(this.getBarChart))
      .subscribe(rslt => {
        this.chartData = rslt;
        this.spinnerService.stopSpinner();
        this.visible = true;
      });
  }

  private getTotalsByMonth(payments: Payment[]): { [month: string]: number } {
    let months = moment.months().reduce((a, b) => { a[b] = 0; return a; }, {});
    return payments
      .filter(p => p.DueDate != null)
      .reduce((a, b) => {
        let month = moment(b.DueDate).format("MMMM");
        a[month] = a[month] + b.Amount;
        return a;
      }, months);
  }

  private getBarChart(monthValues: { [month: string]: number }): any {
    let keys = Object.keys(monthValues);
    return {
      labels: moment.months(),
      datasets: [
        {
          data: keys.map(k => monthValues[k]),
          backgroundColor: '#8a56e2'
        }
      ]
    };
  }

  private setBarChartOptions = () => {
    this.barChartOptions = {
      legend: {
        display: false
      }
    };
  }

}
