import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ConfigService } from 'shaman-utils';
import { DashboardDataModel } from './dashboard.model';
import { AccountReceivableDetails } from 'src/shared/models/account-receivable-details';
import { AccountPayableDetails } from 'src/shared/models/account-payable-details';
import { PaymentDetails } from 'src/shared/models/payment-details.model';
import { DateSortDescending } from 'src/shared/functions/date.functions';

@Injectable()
export class DashboardService extends ApiService {

  protected apiBaseUri: Observable<string>;

  constructor(httpClient: HttpClient, configService: ConfigService<any>) {
    super(httpClient);
    this.apiBaseUri = configService.currentConfiguration.pipe(map(config => config.apiBaseUri));
  }

  getDashboardData = () => {
    return this.get<DashboardDataModel>('/dashboard')
      .pipe(map((data: DashboardDataModel) => {
        data.incomeDetails = data.incomeDetails.map(x => new AccountReceivableDetails(x));
        data.expenseDetails = data.expenseDetails.map(x => new AccountPayableDetails(x));
        data.ledgerDetails = data.ledgerDetails.map(x => new PaymentDetails(x));
        data.ledgerDetails = DateSortDescending(data.ledgerDetails, (ledger) => ledger.PostedDate);
        return new DashboardDataModel(data);
      }))
  }

}
