import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShamanAuthService } from 'shaman-auth';

@Component({
  selector: 'iots-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: ShamanAuthService, private router: Router) { }

  ngOnInit() {
    this.authService.isLoggedIn
      .pipe(takeUntil(this.destroy))
      .subscribe(rslt => {
        if (!rslt) return;
        this.router.navigate(['/'])
      });
  }

  ngOnDestroy() {
    this.destroy.next(true);
  }

  login = (email: string, pwd: string, event: any) => {
    event.preventDefault();
    this.authService.login(email, pwd);
  }

}
