export class WorkHours {
  WorkHoursId: number;
	AccountReceivableId: number;
  PayDate: Date;
  TotalHours: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
}
