import { Industry } from 'src/shared/models/industry.model';
import { Country } from 'src/shared/models/country.model';
import { BusinessEntity } from 'src/shared/models/business-entity.model';

export class BusinessEntityForm {
  name: string;
  type: string;
  industry: Industry;
  phone: string;
  website: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: Country;
  zip_code: string;

  constructor() {
    this.type = 'Public';
  }

  get isValid(): boolean {
    if (!this.name) return false;
    if (!this.type) return false;
    if (!this.industry) return false;
    return true;
  }

  getObjectModel(): BusinessEntity {
    let entity = new BusinessEntity();
    entity.IndustryId = this.industry.IndustryId;
    entity.EntityType = this.type;
    entity.Name = this.name;
    entity.Phone = this.phone;
    entity.Website = this.website;
    entity.Address1 = this.address1;
    entity.Address2 = this.address2;
    entity.City = this.city;
    entity.State = this.state;
    entity.ZipCode = this.zip_code;
    entity.Country = this.country.CountryName;
    return entity;
  }
}
