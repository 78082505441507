import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { DashboardComponent } from './dashboard.component';
import { FontAwesomeSharedModule } from '../../shared/modules/font-awesome.module';
import { ShamanAuthModule } from 'shaman-auth';
import { ResourceService } from '../../shared/services/resource.service';
import { DashboardService } from './dashboard.service';
import { DashboardWidgetModule } from './widgets/dashboard-widget.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeSharedModule,
    ButtonModule,
    CardModule,
    ShamanAuthModule,
    DashboardWidgetModule
  ],
  declarations: [
    DashboardComponent
  ],
  providers: [
    ResourceService,
    DashboardService
  ]
})
export class DashboardModule { }
