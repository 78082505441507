import { IncomeTypeProvider } from '../income-type-provider.interface';
import { AccountReceivableDetails } from 'src/shared/models/account-receivable-details';

export class FlatRateProvider implements IncomeTypeProvider {

  get receivableType(): string { return 'Flat Rate'; };

  getMonthlyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    // BASED ON PAYCHECK HEURISTICS, NOT MATHEMATIC DEFINITIONS OF TIME
    switch(account.IntervalType) {
      case 'Monthly': return account.Amount;
      case 'Bi-Weekly': return account.Amount * 2;
      case 'Weekly': return account.Amount * 4;
    }
    return 0;
  }

  getBiWeeklyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    // BASED ON PAYCHECK HEURISTICS, NOT MATHEMATIC DEFINITIONS OF TIME
    switch(account.IntervalType) {
      case 'Monthly': return account.Amount / 2;
      case 'Bi-Weekly': return account.Amount;
      case 'Weekly': return account.Amount * 2;
    }
    return 0;
  }

  getWeeklyIncome(account: AccountReceivableDetails): number {
    if (!account.Amount || account.Amount == 0) return 0;
    // BASED ON PAYCHECK HEURISTICS, NOT MATHEMATIC DEFINITIONS OF TIME
    switch(account.IntervalType) {
      case 'Monthly': return account.Amount / 4;
      case 'Bi-Weekly': return account.Amount / 2;
      case 'Weekly': return account.Amount;
    }
    return 0;
  }

}
