import { Payment } from 'src/shared/models/payment.model';

import * as moment_ from 'moment';
const moment = moment_;

export class PaymentForm {

  private _paymentType: string;
  get paymentType(): string { return this._paymentType; }
  private _accountId: number;
  get accountId(): number { return this._accountId; }
  paymentId: number;
  paid: boolean;
  amount: number;
  dueDate: Date;
  postedDate: Date;

  constructor(paymentType: string, accountId: number) {
    this._paymentType = paymentType;
    this._accountId = accountId;
    this.paid = false;
  }

  updateForm(obj: Payment = null) {
    if (!obj) return;
    this.paymentId = obj.PaymentId;
    this.paid = !!obj.PostedDate;
    this.amount = obj.Amount;
    this.dueDate = moment(obj.DueDate).toDate();
    if (!!obj.PostedDate) this.postedDate = moment(obj.PostedDate).toDate();
  }

  get isValid(): boolean {
    if (this.paymentType != 'payable' && this.paymentType != 'receivable') return false;
    if (!this.amount) return false;
    if (!this.dueDate) return false;
    return true;
  }

  getObjectModel(): Payment {
    let payment = new Payment();
    payment.PaymentId = this.paymentId;
    payment.PaymentType = this.paymentType;
    payment.AccountId = this.accountId;
    payment.Amount = this.amount;
    payment.DueDate = this.dueDate;
    if (!!this.paid) payment.PostedDate = this.postedDate;
    else payment.PostedDate = null;
    return payment;
  }
}
