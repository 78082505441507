import { Component, Input } from '@angular/core';
import { DashboardWidget } from '../dashboard-widget';
import { DashboardDataModel } from '../../dashboard.model';
import { Paycheck } from 'src/shared/models/paycheck';
import { AccountPayableDetails } from 'src/shared/models/account-payable-details';

@Component({
  selector: 'iots-pending-payments-widget',
  templateUrl: './pending-payments.component.html',
  styles: []
})
export class PendingPaymentsWidget extends DashboardWidget {

  // ABSTRACT PROPERTIES
  @Input() paycheck: Paycheck;
  private _model: DashboardDataModel;
  get model(): DashboardDataModel { return this._model; };
  @Input() set model(value: DashboardDataModel) {
    if (!value) return;
    this._model = value;
    this.loadPendingPayments(value);
  };
  // WIDGET PROPERTIES
  pendingPayments: AccountPayableDetails[] = [];

  constructor() { super(); }

  private loadPendingPayments = (model: DashboardDataModel) => {
    if (!model.expenseDetails) return;
    let end = this.moment(Date.now()).add(7, 'days');
    this.pendingPayments = model.expenseDetails
      .filter(bill => {
        if (this.moment(bill.billDue).isBefore(end)) return true;
        return false;
      })
      .sort((a: AccountPayableDetails, b: AccountPayableDetails) => {
        return this.moment(a.billDue).isBefore(this.moment(b.billDue)) ? -1 : 1;
      });
  }

}
