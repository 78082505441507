export class PaymentDetails {
  PaymentId: number;
  PaymentType: string;
  AccountId: number;
  AccountName: string;
  Amount: number;
  DueDate: Date;
  PostedDate: Date;

  constructor(obj: any) {
    if (!obj) return;
    Object.assign(this, obj);
  }
}
