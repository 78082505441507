export class Withholding {
  WithholdingId: number;
	AccountReceivableId: number;
  EmailAddress: string;
  WithholdingName: string;
	Rate: number;
	RateIndicator: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
}
