import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChartModule } from 'primeng/chart';
import { MessageService } from 'primeng/api';

import { AccountsComponent } from './accounts.component';
import { FontAwesomeSharedModule } from '../../shared/modules/font-awesome.module';
import { ShamanAuthModule } from 'shaman-auth';
import { ManageBillsComponent } from './manage-bills/manage-bills.component';
import { AccountService } from '../../shared/services/account.service';
import { ResourceService } from '../../shared/services/resource.service';
import { BusinessEntityFormComponent } from './forms/business-entity-form/business-entity-form.component';
import { AccountPayableFormComponent } from './forms/account-payable-form/account-payable-form.component';
import { AccountReceivableFormComponent } from './forms/account-receivable-form/account-receivable-form.component';
import { PaymentService } from 'src/shared/services/payment.service';
import { ManageIncomeComponent } from './manage-income/manage-income.component';
import { WithholdingService } from 'src/shared/services/withholding.service';
import { WorkHoursService } from 'src/shared/services/work-hours.service';
import { ExpenseByMonthReport } from './reports/expense-by-month/expense-by-month.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FontAwesomeSharedModule,
    InputTextModule,
    ButtonModule,
    MenuModule,
    TabMenuModule,
    PanelModule,
    CardModule,
    SidebarModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    InputSwitchModule,
    ChartModule,
    ShamanAuthModule
  ],
  declarations: [
    AccountsComponent,
    ManageBillsComponent,
    BusinessEntityFormComponent,
    AccountPayableFormComponent,
    AccountReceivableFormComponent,
    ManageIncomeComponent,
    ExpenseByMonthReport
  ],
  providers: [
    MessageService,
    AccountService,
    ResourceService,
    PaymentService,
    WithholdingService,
    WorkHoursService
  ]
})
export class AccountsModule { }
