import { Component, Input } from '@angular/core';
import { DashboardWidget } from '../dashboard-widget';
import { DashboardDataModel } from '../../dashboard.model';
import { Paycheck } from 'src/shared/models/paycheck';
import { AccountPayableDetails } from 'src/shared/models/account-payable-details';

@Component({
  selector: 'iots-paycheck-widget',
  templateUrl: './paycheck.component.html',
  styles: []
})
export class PaycheckWidget extends DashboardWidget {

  // ABSTRACT PROPERTIES
  @Input() model: DashboardDataModel;
  private _paycheck: Paycheck;
  get paycheck(): Paycheck { return this._paycheck; };
  @Input() set paycheck(value: Paycheck) {
    if (!value) return;
    this._paycheck = value;
  };
  paycheckBills: AccountPayableDetails[];
  paycheckBillsTotal: number;
  paycheckBillsVisible: boolean = false;

  constructor() { super(); }

  viewBillsForCheck = () => {
    this.paycheckBills = this.getBillsForPaycheck();
    this.paycheckBillsTotal = this.paycheckBills.reduce((a,b) => {
      return a + b.AveragePaymentAmount || b.Amount;
    }, 0);
    this.paycheckBillsVisible = true;
  }

  private getBillsForPaycheck = (): AccountPayableDetails[] => {
    if (!this.model.expenseDetails) return;
    return this.model.expenseDetails
      .map(bill => {
        bill.paycheckDate = bill.getPaymentForPaycheck(this.paycheck);
        return bill;
      })
      .filter(bill => {
        if (this.moment(bill.paycheckDate).isBefore(this.paycheck.paycheckDate)) return false;
        if (this.moment(bill.paycheckDate).isAfter(this.paycheck.nextPaycheckDate)) return false;
        return true;
      })
      .sort((a: AccountPayableDetails, b: AccountPayableDetails) => {
        return this.moment(a.paycheckDate).isBefore(this.moment(b.paycheckDate)) ? -1 : 1;
      });
  }

}
