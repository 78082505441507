export class Payment {
  PaymentId: number;
  PaymentType: string;
  AccountId: number;
  Amount: number;
  DueDate: Date;
  PostedDate: Date;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
}
