import { IncomeProvider } from '../providers/income.provider';

export class AccountReceivableDetails {

  private incomeProvider: IncomeProvider;
  AccountReceivableId: number;
  EmailAddress: string;
  AccountName: string;
  BusinessEntityId: number;
  BusinessEntityName: string;
  IndustryName: string;
  EntityType: string;
  IntervalType: string;
  ReceivableType: string;
  StartDate: Date;
  Amount: number;
  LastPayDate: Date;
  LastTotalHours: number;
  AverageTotalHours: number;
  CurrencyWithheld: number;
  PercentWithheld: number;
  Website: string;
  AccountStatus: string;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;

  constructor(obj: any = null) {
    this.incomeProvider = new IncomeProvider();
    if (!obj) return;
    Object.assign(this, obj);
  }

  getMonthlyWithholdings = (): number => {
    if (!this.AccountReceivableId) return 0;
    return this.getWithholdings(this.incomeProvider.getMonthlyIncome(this));
  }

  getBiWeeklyWithholdings = (): number => {
    if (!this.AccountReceivableId) return 0;
    return this.getWithholdings(this.incomeProvider.getBiWeeklyIncome(this));
  }

  getWeeklyWithholdings = (): number => {
    if (!this.AccountReceivableId) return 0;
    return this.getWithholdings(this.incomeProvider.getWeeklyIncome(this));
  }

  private getWithholdings = (amount: number): number => {
    let lessCurrency = !!this.CurrencyWithheld ? this.CurrencyWithheld : 0;
    let lessPercent = 0;
    if (!!this.PercentWithheld && this.PercentWithheld > 0) {
      lessPercent = amount * (this.PercentWithheld / 100);
    }
    return lessCurrency + lessPercent;
  }

}
