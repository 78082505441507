export class AccountReceivable {
  AccountReceivableId: number;
  BusinessEntityId: number;
  PaymentIntervalId: number;
  AccountReceivableTypeId: number;
  EmailAddress: string;
  Name: string;
  AccountStatus: string;
  StartDate: Date;
  Amount: number;
	CreatedBy: string;
	CreatedDate: Date;
	ModifiedBy: string;
	ModifiedDate: Date;
}
