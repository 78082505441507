import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ConfigService } from 'shaman-utils';
import { Payment } from '../models/payment.model';

@Injectable()
export class PaymentService extends ApiService {

  protected apiBaseUri: Observable<string>;

  constructor(httpClient: HttpClient, configService: ConfigService<any>) {
    super(httpClient);
    this.apiBaseUri = configService.currentConfiguration.pipe(map(config => config.apiBaseUri));
  }

  getPayablePayments = (accountId: number): Observable<Payment[]> => {
    return this.get(`/payments/payable/${accountId}`)
      .pipe(map(data => {
        return data.sort((a: Payment, b: Payment) => a.DueDate < b.DueDate ? 1 : -1);
      }));
  }

  addPayment = (payment: Payment): Observable<void> => {
    return this.put(`/payments/${payment.PaymentType}`, payment);
  }

  updatePayment = (payment: Payment): Observable<void> => {
    return this.post(`/payments`, payment);
  }

}
