import { AccountPayable } from 'src/shared/models/account-payable.model';
import { BusinessEntity } from 'src/shared/models/business-entity.model';

export class ManageBillForm {

  state: string;
  businessEntity: BusinessEntity;
  accountPayable: AccountPayable;

  constructor(data: AccountPayable = null) {
    this.state = 'choose-entity';
    if (!data) return;
  }

  changeState = (state: string) => {
    this.state = state;
  }

}
