import { IncomeTypeProvider } from './income-type-provider.interface';
import { AnnualSalaryProvider } from './income-types/annual-salary.provider';
import { AccountReceivableDetails } from '../models/account-receivable-details';
import { FlatRateProvider } from './income-types/flat-rate.provider';
import { HourlyWageProvider } from './income-types/hourly-wage.provider';

export class IncomeProvider {

  typeProviders: IncomeTypeProvider[];

  constructor() {
    this.typeProviders = [
      new AnnualSalaryProvider(),
      new FlatRateProvider(),
      new HourlyWageProvider()
    ];
  }

  getMonthlyIncome(account: AccountReceivableDetails): number {
    let provider = this.typeProviders.find(p => p.receivableType == account.ReceivableType);
    if (!provider) return 0;
    return provider.getMonthlyIncome(account);
  }

  getBiWeeklyIncome(account: AccountReceivableDetails): number {
    let provider = this.typeProviders.find(p => p.receivableType == account.ReceivableType);
    if (!provider) return 0;
    return provider.getBiWeeklyIncome(account);
  }

  getWeeklyIncome(account: AccountReceivableDetails): number {
    let provider = this.typeProviders.find(p => p.receivableType == account.ReceivableType);
    if (!provider) return 0;
    return provider.getWeeklyIncome(account);
  }

}
