import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeSharedModule } from './font-awesome.module';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';

import { HeaderComponent } from '../layout/header/header.component';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { SpinnerComponent } from '../layout/spinner/spinner.component';
import { SpinnerService } from '../layout/spinner/spinner.service';
import { SidebarService } from '../layout/sidebar/sidebar.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NoopAnimationsModule,
    FontAwesomeSharedModule,
    ProgressSpinnerModule,
    SidebarModule
  ],
  declarations: [HeaderComponent, SidebarComponent, SpinnerComponent],
  providers: [SpinnerService, SidebarService],
  exports: [HeaderComponent, SidebarComponent, SpinnerComponent]
})
export class NavigationModule { }
