import { Component, Input } from '@angular/core';
import { DashboardWidget } from '../dashboard-widget';
import { DashboardDataModel } from '../../dashboard.model';
import { Paycheck } from 'src/shared/models/paycheck';

@Component({
  selector: 'iots-income-chart-widget',
  templateUrl: './income-chart.component.html',
  styles: []
})
export class IncomeChartWidget extends DashboardWidget {

  // ABSTRACT PROPERTIES
  @Input() paycheck: Paycheck;
  private _model: DashboardDataModel;
  get model(): DashboardDataModel { return this._model; };
  @Input() set model(value: DashboardDataModel) {
    if (!value) return;
    this._model = value;
    this.loadIncomePieChart(value);
  };
  // WIDGET PROPERTIES
  chartData: any;

  constructor() { super(); }

  private loadIncomePieChart = (model: DashboardDataModel) => {
    if (!model.incomeDetails) return 0;
    let chartData = this.generateBlankChart();
    let witholdings = model.incomeDetails.reduce((a, b) => a + b.getMonthlyWithholdings(), 0);
    chartData.labels = ["Net Income", "Withholdings", "Expenses"];
    chartData.datasets[0].data = [
      this.model.totalMonthlyIncome - witholdings - this.model.averageMonthlyExpenses,
      witholdings,
      this.model.averageMonthlyExpenses
    ];
    chartData.datasets[0].backgroundColor = [
      this.chartColors[0],
      this.chartColors[1],
      this.chartColors[5]
    ];
    this.chartData = chartData;
  }

}
